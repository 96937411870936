.radial-gradient-healthy {
    background: radial-gradient(796.38% 101.27% at 91.2% 50%, #3DD571 21.5%, #00C187 100%);
}

.radial-gradient-unhealthy {
    background: radial-gradient(661.1% 84.11% at 95.6% 45.62%, #FFB800 0%, #E78B00 100%);
}

.radial-gradient-very-unhealthy {
    background: radial-gradient(696.63% 89.43% at 11.48% 43.22%, #FF5F7C 0%, #FE5AB2 100%);
}

.box-shadow-healthy {
    box-shadow: 0px 4px 60px 0px rgba(74, 234, 135, 0.40);
}

.box-shadow-unhealthy {
    box-shadow: 0px 4px 60px 0px rgba(252, 181, 74, 0.50);
}

.box-shadow-very-unhealthy {
    box-shadow: 0px 4px 70px 0px rgba(255, 119, 144, 0.70);
}

.indicator-panel-gradient {
    background: linear-gradient(0deg, #113B3C 0%, #113b3c00 100%);
}

.story-gradient-left {
    opacity: 0.35;
    background: linear-gradient(90deg, #072B2E 0%, rgba(7, 43, 46, 0) 100%);
}

.story-gradient-right {
    opacity: 0.8;
    background: linear-gradient(270deg, #072b2e 0%, rgba(7, 43, 46, 0) 100%);
}