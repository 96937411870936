@import './utils/typography-mixins';

.huge {
    @include huge;
}

h1,
.h1 {
    @include h1;
}

h2,
.h2 {
    @include h2;
}

.h2-alt {
    @include h2-alt;
}

h3,
.h3 {
    @include h3;
}

h4,
.h4 {
    @include h4;
}

// Used in LivingCitiesCompassBlock for stat values
.stat {
    @include stat;
}

.headline-alt {
    @include headline-alt;
}

.headline {
    @include headline;
}

.label {
    @include label;
}

p,
.body-1 {
    @include body-1;
}

.body-2 {
    @include body-2;
}

.body-3 {
    @include body-3;
}

.caption {
    @include caption;
}
