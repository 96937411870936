@import './typography';
@import './gradients';
@import './components';
@import './text-shadows';

@tailwind components;
@tailwind utilities;

@layer tailwind {
  @tailwind base;

  html {
    font-family: var(--font-PlusJakartaSans), system-ui, sans-serif;
  }
  
  body {
    overflow-x: hidden;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    ::selection {
      @apply text-lightGreen;
      @apply bg-ecology;
    }

    :focus-visible {
      @apply outline-ecology;
      outline-offset: 6px;
      outline-width: thick;
    }

    // Stops scrolling on the body if a menu is open, or a modal is open
    &.no-body-scroll {
      overflow: hidden;
    }

    // Small pressed effect on all buttons
    button:active {
      transform: translateY(1px);
    }

    // Each page type can add a `isLight` or `isDark` class to the body
    // which will set the text and background colors accordingly
    // .isLight,
    &:has(.isLight) {
      @apply text-darkGreen-100;
      @apply bg-lightGreen;

      main {
        @apply bg-lightGreen;
      }

      .bg-current {
        @apply bg-lightGreen;
      }

      .text-current {
        @apply text-darkGreen-100;
      }

      .fill-current {
        @apply fill-darkGreen-100;
      }

      .hamburger {
        @apply bg-darkGreen-100;
      }
    }

    &:not(:has(.isLight)),
    &:has(.isDark) {
      @apply text-white;
      @apply bg-darkGreen-100;

      main {
        @apply bg-darkGreen-100;
      }

      .bg-current {
        @apply bg-darkGreen-100;
      }
      
      .text-current {
        @apply text-white;
      }

      .fill-current {
        @apply fill-white;
      }

      .hamburger {
        @apply bg-white;
      }
    }
    
    // Used on the home page to style the Header differently from the existing patterns
    &:has(.isLightHeader) {
      nav {
        @apply text-lightGreen;
        @apply bg-transparent;

        .bg-current {
          @apply bg-darkGreen-100;
        }
        
        .text-current {
          @apply text-lightGreen;
        }
    
        .fill-current {
          @apply fill-lightGreen;
        }

        .hamburger {
          @apply bg-lightGreen;
        }
      }
    }
  }
}
