.richText {
    // Layout and decorations for HTML elements inside richText
    h2 {
        margin-top: 2rem;
        margin-bottom: 1.5rem;
    }

    h3, h4 {
        margin-top: 2rem;
        margin-bottom: 1rem;
    }

    p {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    ul,ol {
        margin-top: 1rem;
        margin-bottom: 1rem;
        list-style: inherit;
        margin-left: 24px; // Account for the indicators
    }

    li {
        margin-bottom: 0.5rem;

        &:last-child {
            margin-bottom: 0;
        }
    }

    & > :first-child {
        margin-top: 0;
    }
    & > :last-child {
        margin-bottom: 0;
    }

    // RichText inline links could just use the underline style
    a:not(.link) {
        @apply hover:text-ecology;
        @apply underline;
        @apply hover:decoration-ecology;
    }
}